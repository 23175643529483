import Glide from '@glidejs/glide';
import SimpleLightbox from 'simplelightbox';

function initMobileNav() {
  const toggleEl = document.querySelector('.header .mobile-nav-toggle');
  const navEl = document.querySelector('.header nav');
  const navListEl = navEl.querySelector('ul');
  const navItems = document.querySelectorAll('.header a');
  const bodyEl = document.querySelector('body');

  function toggleNav(forceClose) {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return;
    }

    if (navEl.dataset.active === 'true' || forceClose) {
      navListEl.style.height = '0';
      navEl.dataset.active = 'false';
      bodyEl.classList.toggle('inactive', false);
    } else {
      navListEl.style.height = `${navListEl.scrollHeight}px`;
      navEl.dataset.active = 'true';
      bodyEl.classList.toggle('inactive', true);
    }
  }

  toggleEl.addEventListener('click', (e) => {
    e.preventDefault();
    toggleNav();
  });

  [...navItems].forEach((itemEl) => {
    itemEl.addEventListener('click', (e) => {
      e.preventDefault();
      toggleNav(true);
      const itemUrl = new URL(itemEl.href);
      const targetEl = document.querySelector(itemUrl.hash);

      window.scrollTo({
        top: targetEl.offsetTop,
        behavior: 'smooth'
      });
    });
  });
}

function setupLightbox() {
  const lightbox = new SimpleLightbox('#Referenzen .images a', {});
}
function ready() {
  initMobileNav();
  setupLightbox();
  new Glide('.glide', {
    type: 'carousel',
    perView: 1,
    autoplay: 6000,
    animationDuration: 1000,
    gap: 0
  }).mount();
  new Glide('.glide-multi-slider', {
    type: 'carousel',
    perView: 1,
    autoplay: 8000,
    animationDuration: 1000,
    gap: 0
  }).mount();
}
if (document.readyState !== 'loading') {
  ready();
} else {
  window.addEventListener('DOMContentLoaded', ready);
}
